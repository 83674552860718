import React, { Component } from 'react';

const UserContext = React.createContext('abc')
export const UserConsumer = UserContext.Consumer

class UserProvider extends Component {
    // Context state
    state = {
      user: {},
    }
  
    // Method to update state
    setUser = (user) => {
      this.setState((prevState) => ({ user }))
    }
  
    render() {
      const { children } = this.props
      const { user } = this.state
      const { setUser } = this
  
      return (
        <UserContext.Provider
          value={{
            user,
            setUser,
          }}
        >
          {children}
        </UserContext.Provider>
      )
    }
  }



export { UserProvider }
//export const UserProvider = UserContext.Provider
export default UserContext