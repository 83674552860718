import React, { Component } from "react";
import { List, Table, Tree } from "antd";


const columnsOverview = [
  {
    title: "Key",
    dataIndex: "key",
    render: text => <b>{text}</b>,
    width: 150
  },
  {
    title: "Value",
    dataIndex: "value"
  }
];

class ReportAntiStatic extends Component {
  constructor(props) {
    super(props);

    const r = this.props.report;

    this.suspicious_strings= r.suspicious_strings;

    this.anti_static = [
      {
        key: "Entropy",
        value: r.entropy
      },
      {
        key: "Packer",
        value: r.packer
      },
      {
        key: "Strings Encoded",
        value: r.strings_encoding.toString()
      },
      {
        key: "Number of symbols",
        value: r.number_of_symbols
      },
      {
        key: "Number of sections",
        value: r.number_of_sections
      },
    ];
  }

  

  render() {
    let pagination = { pageSize: 10, size: "small" };

    return (
      <div className="report-part">
        <h3 className="report-section-headline">Anti Static-Analysis Overview</h3>

         <Table
          columns={columnsOverview}
          dataSource={this.anti_static}
          pagination={false}
          showHeader={false}
          className="table-no-pagination"
        />
        <h3 className="report-section-headline">Strings that may contain hints</h3>

        <List
          dataSource={this.suspicious_strings}
          renderItem={item => <List.Item>{item}</List.Item>}
        />
    </div>
    );
  }
}

export default ReportAntiStatic;
