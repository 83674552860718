import React, { Component } from "react";
import { List, Table, Tree } from "antd";



class ReportAntiDynamic extends Component {
  constructor(props) {
    super(props);

    const r = this.props.report;

    // this.removed_files=r.removed_files;
    this.antivm=r.antivm;
    this.persistence=r.persistence;
    this.proc_rename=r.proc_rename;
    this.antidbg=r.antidbg;
    this.firewall=r.firewall;
    this.antiexec=r.antiexec;
    this.info_gathering=r.info_gathering;
    this.process_inject=r.process_inject;
    this.kernel_modules = r.kernel_modules;
    // this.stage2=r.stage_2_payload;
  }

  

  render() {
    let pagination = { pageSize: 10, size: "small" };

    return (
      <div className="report-part">
        {/* <h3 className="report-section-headline">Removed Files</h3>

        <List
          dataSource={this.removed_files}
          renderItem={item => <List.Item>{item}</List.Item>}
          pagination={pagination}
        /> */}



        <h3 className="report-section-headline">Virtual Machine Detection</h3>

        <List
          dataSource={this.antivm}
          renderItem={item => <List.Item>{item}</List.Item>}
          pagination={pagination}
        />

        <h3 className="report-section-headline">Anti-Debugging</h3>

        <List
          dataSource={this.antidbg}
          renderItem={item => <List.Item>{item}</List.Item>}
          pagination={pagination}
        />

        <h3 className="report-section-headline">Persistence</h3>

        <List
          dataSource={this.persistence}
          renderItem={item => <List.Item>{item}</List.Item>}
          pagination={pagination}
        />

        <h3 className="report-section-headline">Process Renaming</h3>

        <List
          dataSource={this.proc_rename}
          renderItem={item => <List.Item>{item}</List.Item>}
          pagination={pagination}
        />

        <h3 className="report-section-headline">Anti-Execution</h3>

        <List
          dataSource={this.antiexec}
          renderItem={item => <List.Item>{item}</List.Item>}
          pagination={pagination}
        />

        <h3 className="report-section-headline">Information Gathering</h3>

        <List
          dataSource={this.info_gathering}
          renderItem={item => <List.Item>{item}</List.Item>}
          pagination={pagination}
        />

        <h3 className="report-section-headline">Firewall</h3>

        <List
          dataSource={this.firewall}
          renderItem={item => <List.Item>{item}</List.Item>}
          pagination={pagination}
        />

        <h3 className="report-section-headline">Process Injection</h3>

        <List
          dataSource={this.process_inject}
          renderItem={item => <List.Item>{item}</List.Item>}
          pagination={pagination}
        />

        <h3 className="report-section-headline">Loaded Kernel Modules</h3>

        <List
          dataSource={this.kernel_modules}
          renderItem={item => <List.Item>{item}</List.Item>}
          pagination={pagination}
        />

        {/* <h3 className="report-section-headline">Potential Stage 2 Payloads</h3>

        <List
          dataSource={this.stage2}
          renderItem={item => <List.Item>{item}</List.Item>}
          pagination={pagination}
        /> */}

      </div>
    );
  }
}

export default ReportAntiDynamic;
