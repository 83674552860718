import React, { Component } from "react";
import { Layout, Tabs, Spin } from "antd";
import logo from "./../ELFDIGEST.png";

const { Content, Header, Footer } = Layout;
const TabPane = Tabs.TabPane;

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: {},
      loaded: false
    };
  }



  render() {
    const id = this.props.match.params.id;
    const r = this.state.report;

    let innerHTML;



    if (1) {
      innerHTML = (
        <h3 position='centered'>About</h3>

      );
    }

    return (
      <Layout style={{ marginLeft: 200 }}>
        <Header className="header">
          <h2 className="header-headline">ELF DIGEST - Linux Malware Analysis Service</h2>
        </Header>
        <Content className="page-content">
          <div className="inner-page-content">
          {innerHTML}
          <p>ELF DIGEST is a non-profit Linux malware analysis service provided to cyber security professionals, researchers, and academics.<br />
          {/* <img src={logo} style="width:128px;height:128px;" alt="ELFDIGEST" position="centre"/> */}
          It was created by Tolijan Trajanovski (@tolisec), as part of his PhD research at the Univeristy of Manchester, UK, and is based on the open-source Linux sandbox <a href="https://github.com/danieluhricek/LiSa.git" rel="noreferrer">LiSa.</a><br />
          ELF DIGEST performs static, behavioural, and network analysis of malware samples to extract IoCs and identify different suspicious behaviours. <br />
          The static analysis searches for IoCs in the strings and may also identify obfuscation in the form of string encoding and executable packing. <br />
          The behavioural analysis can recognize various malicious actions, including VM detection, anti-debugging, persistence, process injection, loading of kernel modules, firewall configuration changes, and others. <br />
          The network analysis can identify C2 endpoints, botnet type, resolved domains, HTTP requests, port scanning, exploits, and stage-two payloads. <br />
          In addition, ELF DIGEST uses the open-source malware labelling tool AvClass to determine the most probable malware family the analysed sample belongs to. <br />
          The currently supported CPU architectures include ARMv5, ARMv7, MIPS, x86 and x86_64. <br /> 
          The findings and artifacts of the analysis are also shared via MalwareBazaar and Virustotal, unless the 'do not share' option is selected.<br />
          
          </p>

          <h3>How to Register</h3>
                    <p>Registration requests are manually approved to prevent abuse of the service.<br />
                    Please send a registration request with the following details to elfdigest AT GMAIL DOT COM:<br />
                    Work/Organization email:<br />
                    First Name:<br />
                    Last Name:<br />
                    Organization/Company:<br />
                    Role:<br /><br />
                    Once your registration is approved you will be contacted by e-mail.</p>
          </div>
        </Content>
        <Footer className="footer">
          ELF DIGEST
        </Footer>
      </Layout>
    );
  }
}

export default HomePage;
