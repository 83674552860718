import React, { Component } from "react";
import { Layout, Tabs, Spin } from "antd";
import { withOktaAuth } from '@okta/okta-react';

import ReportOverview from "../components/ReportOverview";
import ReportOverviewNetwork from "../components/ReportOverviewNetwork";
import ReportStatic from "../components/ReportStatic";
import ReportDynamic from "../components/ReportDynamic";
import ReportNetwork from "../components/ReportNetwork";
import ReportAntiStatic from "../components/ReportAntiStatic";
import ReportAntiDynamic from "../components/ReportAntiDynamic";
import ReportVirustotal from "../components/ReportVirustotal";

const { Content, Header, Footer } = Layout;
const TabPane = Tabs.TabPane;

export default withOktaAuth(class ReportPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      report: {},
      loaded: false
    };
    if(window.token === undefined){ 
      window.token = this.props.oktaAuth.getAccessToken();
    } 
  }

  componentDidMount() {
    this.loadReport();
  }

  loadReport = () => {
    const id = this.props.match.params.id;
    fetch("https://elfdigest.com:2096/report/" + id, { 
      method: 'get', 
      headers: new Headers({
        'Authorization': 'Bearer '+ window.token, 
        'Content-Type': 'application/json'
      })})
    // fetch("https://iotbab.ddns.net:51774/report/" + id)
      .then(res => res.json())
      .then(report => {
        console.log(report);
        if (!report.hasOwnProperty("error")) {
          this.setState({
            report: report,
            loaded: true
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const id = this.props.match.params.id;
    const r = this.state.report;

    if (!this.state.loaded) {
      return (
        <div className="report-missing">
          <Spin size="large" />
          <p>
            Loading report <br />
            Please wait...
          </p>
        </div>
      );
    }

    let innerHTML;

    if (r.type === "pcap") {
      innerHTML = (
        <Tabs defaultActiveKey="1" className="tabs-report">
          <TabPane tab="Overview" key="1">
            <div className="inner-pane">
              <ReportOverviewNetwork report={r} id={id} />
            </div>
          </TabPane>

          <TabPane tab="Network Analysis" key="4">
            <div className="inner-pane">
              <ReportNetwork report={r.network_analysis} />
            </div>
          </TabPane>
        </Tabs>
      );
    }

    if (r.type === "binary") {
      innerHTML = (
        <Tabs defaultActiveKey="1" className="tabs-report">
          <TabPane tab="Overview" key="1">
            <div className="inner-pane">
              <ReportOverview report={r} id={id} />
            </div>
          </TabPane>

          <TabPane tab="Static Analysis" key="2">
            <div className="inner-pane">
              <ReportStatic report={r.static_analysis} />
            </div>
          </TabPane>

          <TabPane tab="Dynamic Analysis" key="3">
            <div className="inner-pane">
              <ReportDynamic report={r.dynamic_analysis} />
            </div>
          </TabPane>

          <TabPane tab="Network Analysis" key="4">
            <div className="inner-pane">
              <ReportNetwork report={r.network_analysis} />
            </div>
          </TabPane>
          <TabPane tab="Anti Static" key="5">
            <div className="inner-pane">
              <ReportAntiStatic report={r.anti_static} />
            </div>
          </TabPane>
          <TabPane tab="Behavioural Analysis" key="6">
            <div className="inner-pane">
              <ReportAntiDynamic report={r.behavioural_analysis} />
            </div>
          </TabPane>
          <TabPane tab="VirusTotal" key="7">
            <div className="inner-pane">
              <ReportVirustotal report={r.virustotal} />
            </div>
          </TabPane>
        </Tabs>
      );
    }

    return (
      <Layout style={{ marginLeft: 200 }}>
        <Header className="header">
          <h2 className="header-headline">Analysis report</h2>
        </Header>
        <Content className="page-content">{innerHTML}</Content>
        <Footer className="footer">
          ELF DIGEST
        </Footer>
      </Layout>
    );
  }
});

