import React, { Component } from "react";
import { List, Table, Tree } from "antd";


const columnsOverview = [
  {
    title: "Key",
    dataIndex: "key",
    render: text => <b>{text}</b>,
    width: 150
  },
  {
    title: "Value",
    dataIndex: "value"
  }
];

class ReportVirustotal extends Component {
  constructor(props) {
    super(props);

    const r = this.props.report;
    const vtdata = r.data.attributes.last_analysis_stats;
    this.labels=r.AvClass2;
    this.id = r.data.id;
    this.vtlink = `https://www.virustotal.com/gui/file/${this.id}/detection`
    this.meaningful_strings = r.meaningful_strings;

    this.scans = [
      {
        key: "harmless",
        value: vtdata.harmless
      },
      {
        key: "malicious",
        value: vtdata.malicious
      },
      {
        key: "suspicious",
        value: vtdata.suspicious
      },
      {
        key: "undetected",
        value: vtdata.undetected
      },
      {
        key: "failure",
        value: vtdata.failure
      },
    ];
  }

  

  render() {
    let pagination = { pageSize: 10, size: "small" };

    return (
      <div className="report-part">
        <h3 className="report-section-headline">VirusTotal Scan Results</h3>

         <Table
          columns={columnsOverview}
          dataSource={this.scans}
          pagination={false}
          showHeader={false}
          className="table-no-pagination"
        />
        <p> URL: <a href={this.vtlink}>{this.vtlink} </a></p>

        <h3 className="report-section-headline">AVClass2 Malware Labels</h3>
        <p> Labels: {this.labels} </p>
        
    </div>
    );
  }
}

export default ReportVirustotal;
