import React, { Component, useContext } from 'react';
import { Layout, Tabs, Spin } from "antd";
import { withOktaAuth } from '@okta/okta-react';

const { Content, Header, Footer } = Layout;
const TabPane = Tabs.TabPane;

async function checkUser() {
  if (this.props.authState.isAuthenticated && !this.state.userInfo) {
    const userInfo = await this.props.oktaAuth.getUser();
    if (this._isMounted) {
      this.setState({ userInfo: userInfo });
      this.loadApi();
    }
  }
}

export default withOktaAuth(class Account extends Component {
     _isMounted = false;

  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.state = { userInfo: null, api: null };
    this.checkUser = checkUser.bind(this);
    if(window.token === undefined){ 
      window.token = this.props.oktaAuth.getAccessToken();
    } 
  }

  async login() {
    await this.props.oktaAuth.signInWithRedirect();
  }

  async logout() {
    await this.props.oktaAuth.signOut('/');
  }

    async componentDidMount() {
      this._isMounted = true;
      this.checkUser();

    }

    async componentDidUpdate() {
      this._isMounted = true;
      this.checkUser();
      if(window.token === undefined){ 
        window.token = this.props.oktaAuth.getAccessToken();
      }
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    loadApi = () => {
      //fetch("http://" + process.env.REACT_APP_HOST + "/api/tasks/finished")
      fetch("https://api.elfdigest.com:2083/apikey/"+ this.state.userInfo.email, { 
        method: 'get', 
        headers: new Headers({
          'Authorization': 'Bearer '+ window.token, 
          'Content-Type': 'application/json'
        })})
    // fetch("https://iotbab.ddns.net:51774/public")
      .then(res => res.json())
      .then(api => {
        //console.log(api);
        if (!api.hasOwnProperty("error")) {
          this.setState({ api: api });
        }
      })
      .catch(error => {
        console.log(error);
      });
    }

    



  render() {
      let innerHTML;
      const { userInfo, api } = this.state;
    if (1) {
      innerHTML = (
        <h3 position='centered'>Account Info</h3>

      );
    }
    if (this.props.authState.isPending) return <div>Loading...</div>;
      if(this.props.authState.isAuthenticated && this.state.api === null)
      {
        return (
          <div className="report-missing">
            <Spin size="large" />
            <p>
            Loading user info... <br />
            </p>
          </div>
        );
        }
    else
    {
      return(
        <Layout style={{ marginLeft: 200 }}>
          <Header className="header">
            <h2 className="header-headline">Your Account</h2>
          </Header>
          <Content className="page-content">
            <div className="inner-page-content">
            {innerHTML}
            {this.state.userInfo===false && (
              <div>
                <p>Loading user info...</p>
              </div>
            )}
              

            {this.state.userInfo && (
              <div>
                <p>Welcome back, {this.state.userInfo.name}!</p>
              </div>
            )}
              {/* <p>Your token: {window.token} </p> */}
                
            
              <h3>API KEY</h3>
              <p>Your API key: </p>
              {Object.entries(this.state.api).map(([key, value]) => {
            return (
              <div>
                {value}
              </div>
            );
          })}
            <p></p>
             <button onClick={this.logout}>Logout</button>
            </div>
  
          </Content>
          <Footer className="footer">
            ELF DIGEST
          </Footer>
        </Layout>);}
    }

});
