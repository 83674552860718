import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Table } from "antd";
import { withOktaAuth } from '@okta/okta-react';

const { Content, Header } = Layout;

const columnsResults = [
  {
    title: "ID",
    dataIndex: "task_id",
    width: 450,
    render: text => (
      <Link to={"/result/" + text}>
        <code>{text}</code>
      </Link>
    )
  },
  {
    title: "File",
    render: (text, record) => <span>{record.result.filename}</span>
  },
  {
    title: "Time",
    dataIndex: "date_done",
    width: 350
  }
];

export default withOktaAuth(class ResultsPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      results: []
    };
    if(window.token === undefined){ 
      window.token = this.props.oktaAuth.getAccessToken();
    } 
    

  }

  componentDidMount() {
    this.loadResults();
  }


    loadResults = () => {
      //fetch("http://" + process.env.REACT_APP_HOST + "/api/tasks/finished")
      fetch("https://elfdigest.com:2096/public", { 
        method: 'get', 
        headers: new Headers({
          'Authorization': 'Bearer '+ window.token, 
          'Content-Type': 'application/json'
        })})
      .then(res => res.json())
      .then(results => {
        console.log(results);
        if (!results.hasOwnProperty("error")) {
          this.setState({ results });
        }
      })
      .catch(error => {
        console.log(error);
      });
    }


  render() {
    let pagination = { pageSize: 20, size: "small" };

    return (
      <Layout style={{ marginLeft: 200 }}>
        <Header className="header">
          <h2 className="header-headline">Reports</h2>
        </Header>
        <Content className="page-content">
          <div className="inner-page-content">
            <Table
              columns={columnsResults}
              dataSource={this.state.results}
              pagination={pagination}
              rowKey="sha256"
            />
          </div>
        </Content>
      </Layout>
    );
    
  }
});
