import React, { Component } from "react";
import { List, Table, Tree } from "antd";

const { TreeNode } = Tree;

// const columnsSyscalls = [
//   {
//     title: "Syscall",
//     dataIndex: "name",
//     width: 200
//   },
//   {
//     title: "Arguments",
//     dataIndex: "arguments"
//   },
//   {
//     title: "Return",
//     dataIndex: "return",
//     width: 200
//   }
// ];

const columnsProcCalls = [
  {
    title: "PID",
    dataIndex: "pid",
    width: 50
  },
  {
    title: "File",
    dataIndex: "file",
    width: 100
  },
  {
    title: "Syscall",
    dataIndex: "syscall",
    width: 200
  }
];

class ReportStatic extends Component {
  constructor(props) {
    super(props);

    const r = this.props.report;

    this.openFiles = r.open_files;
    if("modified_files" in r){
      this.modified_files=r.modified_files;
    }
    else{
      this.modified_files=[];
    }

    if("removed_files" in r){
      this.removed_files=r.removed_files;
    }
    else{
      this.removed_files=[];
    }

    if("executed_commands" in r){
      this.executed_commands=r.executed_commands;
    }
    else{
      this.executed_commands=[];
    }

    // this.syscalls = [];
    // for (let i = 0; i < r.syscalls.length; i++) {
    //   this.syscalls.push({
    //     key: i,
    //     name: r.syscalls[i].name,
    //     arguments: r.syscalls[i].arguments,
    //     return: r.syscalls[i].return
    //   });
    // }

    this.proc_calls = [];
    for (let i = 0; i < r.proc_calls.length; i++) {
      this.proc_calls.push({
        key: i,
        pid: r.proc_calls[i].pid,
        file: r.proc_calls[i].file,
        syscall: r.proc_calls[i].syscall,
      });
    }

    this.processTreeNodes = this.parseProcessFirst();
  }

  parseProcessFirst = () => {
    const processes = this.props.report.ptree;
    let nodes;

    if (processes.length !== 0) {
      let pid = processes[0].pid;
      nodes = (
        <TreeNode title={"PID: " + pid.toString()} key={pid.toString()}>
          {this.parseProcessesWithParent(pid)}
        </TreeNode>
      );
    }

    return nodes;
  };

  parseProcessesWithParent = parentPID => {
    const processes = this.props.report.ptree;
    let nodes = [];

    for (let i = 0; i < processes.length; i++) {
      let pid = processes[i].pid;
      let parent = processes[i].parent;

      if (parent === parentPID) {
        let node = (
          <TreeNode title={"PID: " + pid.toString()} key={pid.toString()}>
            {this.parseProcessesWithParent(pid)}
          </TreeNode>
        );
        nodes.push(node);
      }
    }

    return nodes;
  };

  render() {
    let pagination = { pageSize: 10, size: "small" };

    let filesPagination = this.openFiles.length === 0 ? false : pagination;

    return (
      <div className="report-part">

        <h3 className="report-section-headline">Opened files</h3>
        <List
          dataSource={this.openFiles}
          renderItem={item => <List.Item>{item}</List.Item>}
          pagination={filesPagination}
        />

        <h3 className="report-section-headline">Removed files</h3>
        <List
          dataSource={this.removed_files}
          renderItem={item => <List.Item>{item}</List.Item>}
          pagination={filesPagination}
        />
         <h3 className="report-section-headline">Modified files</h3>
        <List
          dataSource={this.modified_files}
          renderItem={item => <List.Item>{item}</List.Item>}
          pagination={filesPagination}
        />
         <h3 className="report-section-headline">Executed commands</h3>
        <List
          dataSource={this.executed_commands}
          renderItem={item => <List.Item>{item}</List.Item>}
          pagination={filesPagination}
        />
        
        <h3 className="report-section-headline">Process tree</h3>

        <Tree defaultExpandAll className="process-tree">
          {this.processTreeNodes}
        </Tree>


        <h3 className="report-section-headline">Process List</h3>

        <Table
        columns={columnsProcCalls}
        dataSource={this.proc_calls}
        pagination={pagination}
        />
        

        {/*<h3 className="report-section-headline">Syscalls</h3>*/}

        {/*<Table*/}
        {/*  columns={columnsSyscalls}*/}
        {/*  dataSource={this.syscalls}*/}
        {/*  pagination={pagination}*/}
        {/*/>*/}

      </div>
    );
  }
}

export default ReportStatic;
