import React, { Component } from "react";
import { List, Table } from "antd";

const columnsBriefInfo = [
    {
      title: "Key",
      dataIndex: "key",
      render: text => <b>{text}</b>,
      width: 180
    },
    {
      title: "Value",
      dataIndex: "value"
    }
  ];


class ReportBrief extends Component {
    constructor(props) {
      super(props);
  
    // const r = this.props.report;

    this.brief = this.props.report
   
    this.results = [
        {
          key: "Filename",
          value: this.brief.file_name
        },
        {
            key: "SHA256",
            value: this.brief.sha256
          },
        {
          key: "Packer",
          value: this.brief.packer
        },
        {
          key: "Botnet",
          value: this.brief.botnet
        },
        {
          key: "Peer-to-peer",
          value: this.brief.P2P
        },
        {
          key: "Architecture",
          value: this.brief.architecture
        },
        {
            key: "AvClass",
            value: this.brief.AvClass2
        },
        {
        key: "TCP Scanning",
        value: this.brief.tcp_scanning.toString()
        },
        {
            key: "TCP C2",
            value: this.brief.tcp_cnc.toString()
            },
            {
            key: "UDP C2",
            value: this.brief.udp_cnc.toString()
            },
            {
              key: "Domains",
              value: this.brief.dns.toString()
              },
            {
                key: "Behaviour",
                value: this.brief.behaviour.toString()
                }
      ];

    this.full_analysis = this.props.full_analysis;

  }

  render() {
    const pagination = { pageSize: 5, size: "small" };

    let locale = {
      emptyText: 'not identified',
    };
    return (
      <div className="report-part">
        <h3 className="report-section-headline">Brief Report</h3>
        
        <Table
          columns={columnsBriefInfo}
          dataSource={this.results}
          pagination={false}
          showHeader={false}
          className="table-no-pagination"
          locale={locale}
        />
        
        <h3 className="report-section-headline">Full Report (requires user account)</h3>
            <p><a href={this.full_analysis}>{this.full_analysis}</a></p>

            <h3 className="report-section-headline">How to register</h3>
            <p>ELF DIGEST is a non-profit Linux Malware Analysis service limited to IT professionals, Academics and CERTs</p>
            <p>Registration requests are manually approved to prevent abuse of the service.<br /><br />
                    Please send a registration request with the following details to elfdigest AT GMAIL DOT COM:<br />
                    Work/Organization email:<br />
                    First Name:<br />
                    Last Name:<br />
                    Organization/Company:<br />
                    Role:<br />
                    Once your registration is approved you will be contacted by e-mail<br />
                    Alternatively, you can send the registration request to @tolisec on Twitter</p>

      </div>
    );
  }
}

export default ReportBrief;
