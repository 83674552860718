import React, { Component } from "react";
import { Layout, Tabs, Spin } from "antd";
import { List, Table } from "antd";
import ReportBrief from "../components/ReportBrief"; 
const { Content, Header, Footer } = Layout;
const TabPane = Tabs.TabPane;



class BriefReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: {},
      loaded: false
    };
  }

  componentDidMount() {
    this.loadReport();
  }

  loadReport = () => {
    const id = this.props.match.params.id;
    //fetch("http://" + process.env.REACT_APP_HOST + "/api/report/" + id)
    fetch("https://elfdigest.com:2096/brief/" + id)
      .then(res => res.json())
      .then(report => {
        console.log(report);
        if (!report.hasOwnProperty("error")) {
          this.setState({
            report: report,
            loaded: true
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const id = this.props.match.params.id;
    const r = this.state.report;

    if (!this.state.loaded) {
      return (
        <div className="report-missing">
          <Spin size="large" />
          <p>
            Can't load your report? <br />
            Check pending and failed tasks..
          </p>
        </div>
      );
    }

    let innerHTML;

    
      innerHTML = (
        
            <div className="inner-pane">
              <ReportBrief report={r.report} full_analysis={r.full_analysis} />
            </div>
       
      )
    

    return (
      <Layout style={{ marginLeft: 200 }}>
        <Header className="header">
          <h2 className="header-headline">Brief Analysis report</h2>
        </Header>
        <Content className="page-content">{innerHTML}</Content>
        <Footer className="footer">
          ELF DIGEST
        </Footer>
      </Layout>
    );
  }
}

export default BriefReportPage;
