import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Table } from "antd";
import { withOktaAuth } from '@okta/okta-react';

const { Content, Header } = Layout;

async function checkUser() {
    if (this.props.authState.isAuthenticated && !this.state.userInfo) {
      const userInfo = await this.props.oktaAuth.getUser();
      if (this._isMounted) {
        this.setState({ userInfo: userInfo });
        this.loadResults();
      }
    }
  }

const columnsResults = [
  {
    title: "ID",
    dataIndex: "task_id",
    width: 450,
    render: text => (
      <Link to={"/result/" + text}>
        <code>{text}</code>
      </Link>
    )
  },
  {
    title: "Time",
    dataIndex: "time_scan",
    width: 350
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100
  }
];

export default withOktaAuth(class MyResultsPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      results: [],
      userInfo: null
    };
    this.checkUser = checkUser.bind(this);

    if(window.token === undefined){ 
      window.token = this.props.oktaAuth.getAccessToken();
    } 
    

  }

  async componentDidMount() {
    this._isMounted = true;
    this.checkUser();
  }

  async componentDidUpdate() {
    this._isMounted = true;
    this.checkUser();
    if(window.token === undefined){ 
      window.token = this.props.oktaAuth.getAccessToken();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


    loadResults = () => {
      //fetch("http://" + process.env.REACT_APP_HOST + "/api/tasks/finished")
      
      fetch("https://api.elfdigest.com:2083/usertasks/"+this.state.userInfo.email, { 
        method: 'get', 
        headers: new Headers({
          'Authorization': 'Bearer '+ window.token, 
          'Content-Type': 'application/json'
        })})
    // fetch("https://iotbab.ddns.net:51774/public")
      .then(res => res.json())
      .then(results => {
        console.log(results);
        if (!results.hasOwnProperty("error")) {
          this.setState({ results: results });
        }
      })
      .catch(error => {
        console.log(error);
      });
    }


  render() {
    let pagination = { pageSize: 20, size: "small" };
    if (this.props.authState.isPending) return <div>Loading...</div>;
    
    
    
    return (
      <Layout style={{ marginLeft: 200 }}>
        <Header className="header">
          <h2 className="header-headline">Reports</h2>
        </Header>
        <Content className="page-content">  
          <div className="inner-page-content">
            <Table
              columns={columnsResults}
              dataSource={this.state.results}
              pagination={pagination}
              rowKey="sha256"
            /> 
            <p>The status of the tasks is updated every 5 minutes</p>
          </div>
          
        </Content>
      </Layout>
    );
    
  }
});
