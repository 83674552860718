import React, { Component } from "react";
import { Button, Layout, Radio, Alert, Spin, InputNumber } from "antd";
import ReportBrief from "../components/ReportBrief"; 

const { Content, Header } = Layout;

class SearchPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "",
      argument:"",
      report: "",
      notfound:""
    };
  }

  
  handleInputArgument = event => {
    this.setState({
      argument: event.target.value
    });
  };

  loadReport = () => {
    const id = this.props.match.params.id;
    fetch("https://elfdigest.com:2096/brief/" + this.state.argument)
    // fetch("https://iotbab.ddns.net:51774/report/" + id)
      .then(res => res.json())
      .then(report => {
        console.log(report);
        if (!report.hasOwnProperty("error")) {
          this.setState({
            report: report,
            loaded: true
          });
        }
        else{
          this.setState({
            notfound: 'true',
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { status, argument, report, notfound } = this.state;

    let innerHTML;

    
      innerHTML = (
        
            <div className="inner-pane">
              <ReportBrief report={this.state.report.report} full_analysis={this.state.report.full_analysis} />
            </div>
       
      )
    

    let argsForm = (
        <div className="upload-form-row">
          <p className="upload-form-label">
            <label title="Execution time">SHA256</label>
          </p>
          <div className="upload-form-input">
            <input type="text" value={this.state.value} onChange={this.handleInputArgument} defaultValue={''} style={{width:600}}/>
          </div>
        </div>
      );
    
     
      console.log(report) 
    if(report==="" && notfound===""){

    return (
      <Layout style={{ marginLeft: 200 }}>
        <Header className="header">
          <h2 className="header-headline">Search</h2>
        </Header>
        <Content className="page-content">
          <div className="inner-page-content">
            <div className="upload-form">
              
              {argsForm}

              
              <div className="upload-form-row">
                <div className="upload-form-input">
                  <Button
                    type="primary"
                    icon="search"
                    onClick={this.loadReport}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
            <div className="upload-alerts">{alert}</div>
          </div>
        </Content>
      </Layout>
    );
    }else if(report===""){
      return (
        <Layout style={{ marginLeft: 200 }}>
          <Header className="header">
            <h2 className="header-headline">Search</h2>
          </Header>
          <Content className="page-content">
            <div className="inner-page-content">
              <div className="upload-form">
                
                {argsForm}
  
                
                <div className="upload-form-row">
                  <div className="upload-form-input">
                    <Button
                      type="primary"
                      icon="search"
                      onClick={this.loadReport}
                    >
                      Search
                    </Button>
                  </div>
                </div>
              </div>

              <p>File not analysed</p>
            </div>
          </Content>
        </Layout>
      );
    
  } else{

    return (<Layout style={{ marginLeft: 200 }}>
    <Header className="header">
      <h2 className="header-headline">Brief Analysis report</h2>
    </Header>
    <Content className="page-content">{innerHTML}</Content>

  </Layout>
    );
  }
}
}

export default SearchPage;
