import React, { Component } from "react";
import { Button, Layout, Radio, Alert, Spin, InputNumber } from "antd";
import { withOktaAuth } from '@okta/okta-react';

const { Content, Header } = Layout;

async function checkUser() {
  if (this.props.authState.isAuthenticated && !this.state.userInfo) {
    const userInfo = await this.props.oktaAuth.getUser();
    if (this._isMounted) {
      this.setState({ userInfo: userInfo });
    }
  }
}

export default withOktaAuth(class SubmitPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      file: "",
      type: "binary",
      exectime: 120,
      status: "",
      uploading: false,
      task_id: "",
      argument: "",
      share: "",
      userInfo: null,
      leagacy: ""
    };
    this.checkUser = checkUser.bind(this);
    if(window.token === undefined){ 
      window.token = this.props.oktaAuth.getAccessToken();
    } 
  }
  async componentDidMount() {
    this._isMounted = true;
    this.checkUser();
  }

  async componentDidUpdate() {
    this._isMounted = true;
    this.checkUser();
    if(window.token === undefined){ 
      window.token = this.props.oktaAuth.getAccessToken();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleUpload = () => {
    this.setState({
      uploading: true,
      status: ""
    });


    let data = new FormData();

    if (this.state.type === "binary") {
      data.append("file", this.state.file);
      data.append("exec_time", this.state.exectime);
      data.append("legacy", this.state.legacy);
      if(this.state.argument !== ""){
        data.append("argument", this.state.argument);
      }
      data.append("filename", this.state.file.name)
      data.append("userid", this.state.userInfo.email);
      data.append("share", this.state.share);
      fetch("https://api.elfdigest.com:2083/submit/file", {
        method: "POST",
        headers: new Headers({
          'Authorization': 'Bearer '+ window.token, 
        }),
        body: data
      })
        .then(res => {
          let status = "";
          if (res.ok) {
            status = "success";
          } else {
            status = "error";
          }
          this.setState({
            status: status,
            file: ""
          });
          return res.json();
        })
        .then(obj => {
          this.setState({
            task_id: obj.task_id
          });
        })
        .catch(error => {
          console.log(error);
        });
    }

    // if (this.state.type === "pcap") {
    //   data.append("pcap", this.state.file);

    //   fetch("http://" + process.env.REACT_APP_HOST + "/api/tasks/create/pcap", {
    //     method: "POST",
    //     body: data
    //   })
    //     .then(res => {
    //       let status = "";
    //       if (res.ok) {
    //         status = "success";
    //       } else {
    //         status = "error";
    //       }
    //       this.setState({
    //         status: status,
    //         file: ""
    //       });
    //       return res.json();
    //     })
    //     .then(obj => {
    //       this.setState({
    //         task_id: obj.task_id
    //       });
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // }
  };

  handleInput = event => {
    this.setState({
      file: event.target.files[0]
    });
  };

  handleSelectRadio = event => {
    this.setState({
      type: event.target.value
    });
  };

  handleShare = event => {
    this.setState({
      share: event.target.value
    });
  };

  handleLegacy = event => {
    this.setState({
      legacy: event.target.value
    });
  };

  handleInputExecutionTime = value => {
    this.setState({
      exectime: value
    });
  };

  handleInputArgument = event => {
    this.setState({
      argument: event.target.value
    });
  };

  render() {
    const { file, type, status, uploading, task_id } = this.state;

    let disabled = true;
    let alert;

    if (file !== "") {
      disabled = false;
    }

    if (status === "success") {
      alert = (
        <Alert
          message="Success"
          description={
            "File was sucessfuly uploaded. Your task_id is " + task_id
          }
          type="success"
          showIcon
        />
      );
    } else if (status === "error") {
      alert = (
        <Alert
          message="Error"
          description="Error uploading file."
          type="error"
          showIcon
        />
      );
    } else {
      if (uploading) {
        alert = (
          <div className="align-center">
            <Spin size="large" />
          </div>
        );
      }
    }

    let binaryForm;

    if (type === "pcap") {
      binaryForm = <div />;
    } else {
      binaryForm = (
        <div className="upload-form-row">
          <p className="upload-form-label">
            <label title="Execution time">Execution time (seconds) </label>
          </p>
          <div className="upload-form-input">
            <InputNumber
              className="upload-form-exectime-input"
              min={20}
              max={310}
              defaultValue={120}
              onChange={this.handleInputExecutionTime}
            />
          </div>
        </div>
      );
    }

    let argsForm;

    if (type === "pcap") {
      argsForm = <div />;
    } else {
      argsForm = (
        <div className="upload-form-row">
          <p className="upload-form-label">
            <label title="Execution time">Execution argument</label>
          </p>
          <div className="upload-form-input">
            <input type="text" value={this.state.value} onChange={this.handleInputArgument} defaultValue={""} />
          </div>
        </div>
      );
    }

    return (
      <Layout style={{ marginLeft: 200 }}>
        <Header className="header">
          <h2 className="header-headline">Submit file</h2>
        </Header>
        <Content className="page-content">
          <div className="inner-page-content">
            <div className="upload-form">
              {/* <div className="upload-form-row">
                <p className="upload-form-label">
                  <label title="Analysis type">Analysis type</label>
                </p>
                <div className="upload-form-input">
                  <Radio.Group onChange={this.handleSelectRadio}>
                    <Radio.Button value="binary">binary</Radio.Button>
                    <Radio.Button value="pcap">pcap</Radio.Button>
                  </Radio.Group>
                </div>
              </div> */}

              <div className="upload-form-row">
                <p className="upload-form-label">
                  <label title="File">File</label>
                  <span className="upload-file-name">{file.name}</span>
                </p>
                <div className="upload-form-input">
                  <label className="upload-file-input ant-btn ant-btn-default">
                    <input
                      type="file"
                      className="hidden"
                      onChange={this.handleInput}
                    />
                    <span className="upload-label-text">Select file</span>
                  </label>
                </div>
              </div>

              {binaryForm}
              {argsForm}
              <p className="upload-form-label">
                  <label title="Analysis type">Virtual Machine Type</label>
                </p>
              <div className="upload-form-input">
                  <Radio.Group onChange={this.handleLegacy} defaultValue={""}>
                    <Radio.Button value="legacy">Legacy</Radio.Button>
                    <Radio.Button value="" defaultChecked>Normal</Radio.Button>
                  </Radio.Group>
                </div>
                <p className="upload-form-label">
                  <label title="Analysis type">Share analysis report</label>
                </p>
                <div className="upload-form-input">
                  <Radio.Group onChange={this.handleShare} defaultValue={"yes"}>
                    <Radio.Button value="no">No</Radio.Button>
                    <Radio.Button value="yes" defaultChecked>Yes</Radio.Button>
                  </Radio.Group>
                </div>
              <div className="upload-form-row">
                <div className="upload-form-input">
                  <Button
                    type="primary"
                    icon="upload"
                    onClick={this.handleUpload}
                    disabled={disabled}
                    className="upload-submit"
                  >
                    Submit
                  </Button>
                </div>
              </div>
              <p>The "Legacy" VMs should be used for ARM-ARMv5 samples that should be executed on the legacy ARMv5 VM.</p>
            </div>
            <div className="upload-alerts">{alert}</div>
          </div>
        </Content>
      </Layout>
    );
  }
});
