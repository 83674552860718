import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { Layout, Menu, Icon } from "antd";
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';


import ReportPage from "./pages/ReportPage";
import ReportHashPage from "./pages/ReportHashPage";
import ResultsPage from "./pages/ResultsPage";
import MyResultsPage from "./pages/MyResultsPage";

// import ResultsPageAuth from "./pages/ResultsPageAuth";
// import FailedTasksPage from "./pages/FailedTasksPage";
// import PendingTasksPage from "./pages/PendingTasksPage";
// import Login from "./components/Login";
// import AccountInfo from "./pages/Account.jsx"
import APIPage from "./pages/APIPage";

import SubmitPage from "./pages/SubmitPage";
import Account from "./components/Home.jsx"
import HomePage from "./pages/HomePage"
import BriefReportPage from "./pages/BriefReportPage"
import SearchPage from "./pages/SearchPage"

import "./App.css";
import logo from "./ELFDIGEST.png";
import { UserProvider } from './UserContext'
const CALLBACK_PATH = '/callback';

const { Sider } = Layout;

const user = "toli"

const oktaAuth = new OktaAuth({
  issuer: 'https://login.elfdigest.com/oauth2/default',
  clientId: '0oa2bll99Ep0GCZ9g5d6',
  redirectUri: window.location.origin + '/callback'
});
class App extends Component {
  render() {
 
    return (

      <Router>
        <Security oktaAuth={oktaAuth} >

        <Sider id="sider">
          <img src={logo} className="logo" alt="ELFDIGEST"/>
          <Menu theme="light" mode="inline" >
            <Menu.Item key="1">
              <Icon type="cloud" />
              <span className="nav-text">Public Reports</span>
              <Link to="/results" />
            </Menu.Item>
            <Menu.Item key="6">
              <Icon type="cloud" />
              <span className="nav-text">My Reports</span>
              <Link to="/myreports" />
            </Menu.Item>
            <Menu.Item key="2">
              <Icon type="upload" />
              <span className="nav-text">Submit</span>
              <Link to="/submit" />
            </Menu.Item>
            <Menu.Item key="3">
              <Icon type="search" />
              <span className="nav-text">Search</span>
              <Link to="/search" />
            </Menu.Item>
            <Menu.Item key="4">
              <Icon type="user" />
              <span className="nav-text">Account</span>
              <Link to="/account" />
            </Menu.Item>
            
            <Menu.Item key="5">
              <Icon type="bulb" />
              <span className="nav-text">About</span>
              <Link to="/about" />
            </Menu.Item>

            {/* <Menu.Item key="6">
              <Icon type="read" />
              <span className="nav-text">API</span>
              <Link to="/api-doc" />
            </Menu.Item> */}

          </Menu>
        </Sider>
        
        <div id="main-content">
          <Switch>
            
            <Route exact={true} path="/" component={HomePage} />
            <Route path="/about" component={HomePage} />
            <SecureRoute path="/results" component={ResultsPage} />
            <SecureRoute path="/myreports" component={MyResultsPage} />
            <Route path="/failed" component={HomePage} />
            <Route path="/pending" component={HomePage} />
            <SecureRoute path="/result/:id" component={ReportPage} />
            <SecureRoute path ="/submit" component={SubmitPage} />
            {/* <Route path="/resulta/:id" component={ReportPageAuth} /> */}
            <SecureRoute path="/report/:id" component={ReportHashPage} />
            <SecureRoute path="/account" component={Account} />
            <Route path="/brief/:id" component={BriefReportPage} />
            <Route path="/search" component={SearchPage} />
            {/* <SecureRoute path = "/api-doc" component={APIPage} /> */}
            <Route path={CALLBACK_PATH} component={LoginCallback} />
          </Switch>
        </div>
          
    
       
        </Security>
      </Router>
    

      
    );
  }
}

export default App;
